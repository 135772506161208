@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-dark-blue text-gray-200 font-cyber;
}

.app {
  @apply flex;
}

.sidebar {
  @apply w-96 p-6 bg-medium-blue bg-opacity-80 backdrop-filter backdrop-blur-lg shadow-lg overflow-y-auto max-h-screen border-r border-cyber-blue border-opacity-30;
}

.sidebar h3 {
  @apply text-cyber-blue text-2xl mb-6 uppercase tracking-wider;
}

.sidebar label {
  @apply block mb-4 text-sm text-gray-300;
}

.sidebar input,
.sidebar select {
  @apply w-full p-3 mt-1 mb-4 bg-light-blue bg-opacity-50 border border-cyber-blue border-opacity-30 rounded-md text-gray-200 transition duration-300 ease-in-out;
}

.sidebar input:focus,
.sidebar select:focus {
  @apply outline-none border-cyber-blue shadow-neon-blue;
}

.sidebar button {
  @apply w-full py-3 bg-cyber-blue text-dark-blue font-bold uppercase tracking-wider rounded-md cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-neon-blue;
}

.map {
  @apply flex-grow h-screen;
}

.custom-popup {
  @apply bg-medium-blue bg-opacity-90 p-4 rounded-lg shadow-lg backdrop-filter backdrop-blur-md text-gray-200 border border-cyber-blue border-opacity-30;
}

.custom-popup strong {
  @apply text-cyber-blue;
}

/* Glassmorphism card style */
.glass-card {
  @apply bg-medium-blue bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-lg border border-cyber-blue border-opacity-20 shadow-lg;
}

/* Neon text effect */
.neon-text {
  @apply text-cyber-blue;
  text-shadow: 0 0 5px #00ffff, 0 0 20px #00ffff;
}

.gradient-bg {
  @apply bg-gradient-to-r from-cyber-blue via-cyber-purple to-cyber-yellow;
  background-size: 200% 200%;
  animation: gradientShift 15s ease infinite;
}

@keyframes gradientShift {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

/* Pulsing effect */
.pulse-effect {
  @apply animate-pulse-fast;
}

/* Glowing border */
.glow-border {
  @apply border border-cyber-blue;
  box-shadow: 0 0 5px #00ffff, 0 0 20px #00ffff;
}

/* Futuristic button */
.cyber-button {
  @apply bg-cyber-blue text-dark-blue font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-neon-blue;
}

/* Leaflet map adjustments */
.leaflet-container {
  @apply bg-dark-blue !important;
}

.leaflet-control-attribution {
  @apply bg-medium-blue bg-opacity-80 text-gray-400 !important;
}

.leaflet-control-attribution a {
  @apply text-cyber-blue !important;
}

.leaflet-control-zoom a {
  @apply bg-medium-blue bg-opacity-80 text-cyber-blue border-cyber-blue border-opacity-30 !important;
}

.leaflet-control-zoom a:hover {
  @apply bg-cyber-blue bg-opacity-20 !important;
}

.custom-popup .leaflet-popup-content-wrapper,
.custom-popup .leaflet-popup-tip {
  background: transparent;
  color: #e0e0e0;
  box-shadow: none;
}

.custom-popup .leaflet-popup-content-wrapper {
  padding: 0;
}

.custom-popup .leaflet-popup-content {
  margin: 0;
}

.popup-content {
  @apply bg-gray-900 bg-opacity-90 p-4 rounded-lg shadow-lg backdrop-filter backdrop-blur-md text-gray-200 border border-cyber-blue border-opacity-30;
}
